
.container {
  height: 3rem;
  background-color: #353535;
  border-bottom: 1px solid #5F6368;
}

.ctrl_btn {
  color: #3587EB;
  border: 1px solid #5F6368;
  background-color: #353535;
  border-radius: 0.4rem;
  height: 70%;
  margin: 0.5rem;
  padding: 0 0 0 0.5rem ;
}

.ctrl_btn:hover {
  color: #3587EB;
  background-color: #4D4D4D;
}

.ctrl_btn:active {
  color: #3587EB;
  background-color: #1E3368;
}

.btn_img {
  vertical-align: baseline;
}
