
.table_control {
  width: 100%;
  height: 5rem;
  padding: 0.75rem;
}

#delete-user {
  display: flex;
  justify-content: center;
}

.ctrl_btn {
  color: #3587EB;
  border: 1px solid #353535;
  background-color: white;
  border-radius: 0.4rem;
  height: 2rem;
  margin: 0.5rem;
  padding: 0 0 0 0.5rem;
}

.ctrl_btn:hover {
  color: #3587EB;
  background-color: #EBF4FF;
}
