$LIGHT_GRAY_6: #efefef;
$LIGHT_LIGHT_BLUE: #e1efff;
$LIGHT_GRAY_5: #e0e0e0;
$LIGHT_LIGHT_GRAY: #fafafa;
$GRAY_5: #b5b5b5;
$BLACK: #000000;
$DARK_GRAY_1: #353535;
$CIPIA_BLUE: #3587eb;
$LIGHT_BLUE: #77b4ff;
$CIPIA_BLUE_2: #379cd8;
$BLUE_GRAY: #5f6368;
$LIGHTER_BLUE: #a8cfff;
$GRAY_2: #4d4d4d;
$LABEL_COLOR: #707070;
