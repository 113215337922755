@import "../../../css/constants.scss";

.btn {
  user-select: none;
  background-color: transparent;
  color: $GRAY_2;
  border: 1px solid $GRAY_5;
  border-radius: 0.35rem;
  padding: 0.25rem 1rem;
  height: 2.5rem;

  &.disabled {
    cursor: not-allowed;
    opacity: 60%;
  }

  &:not(.disabled) {
    &:hover {
      border-color: $CIPIA_BLUE;
      background-color: $LIGHT_LIGHT_BLUE;
    }
    &:active {
      border-color: $CIPIA_BLUE;
      background-color: $LIGHTER_BLUE;
    }
  }
}
