.nav-link.active {
  border-color: transparent !important;
  background-color: #379CD8 !important;
  color: #fff !important;
}

.nav-tabs .nav-link {
  border: transparent;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding: 5px;
}

li:first-child {
  border-radius: 15px 0px 0px 0px;
}

li:last-child {
  border-radius: 0px 15px 0px 0px;
  border-width: 2px 2px 2px 2px !important;
}

.tab-content {
  border: 3px solid #379CD8; 
}