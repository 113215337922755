@import "../../../css/constants.scss";

.btn {
  border: 2px solid $CIPIA_BLUE_2;
  border-bottom: none;
  padding: 0.4rem 0.65rem;
  border-radius: 0;
  background-color: white;

  &.active {
    background-color: $CIPIA_BLUE_2;
    &:hover {
      background-color: $LIGHT_BLUE;
    }
  }
  &:hover {
    background-color: $LIGHT_LIGHT_BLUE;
    border-color: $CIPIA_BLUE_2;
  }

  &:first-of-type {
    border-top-left-radius: 1rem;
  }
  &:last-of-type {
    border-top-right-radius: 1rem;
  }
  &:not(:first-of-type) {
    border-left-width: 1px;
  }
  &:not(:last-of-type) {
    border-right-width: 1px;
  }
}
