.passwordToggle {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
}
.inputWithEnteredValue {
  background: #ffffff !important;
}
.inputGroupText {
  height: 2rem;
}
