@import "../../css/constants.scss";

.nav_container {
  height: 3rem;
  background-color: #1e3368;
}

.nav-btn {
  color: white !important;
  border: 2px solid transparent;
  border-radius: 0.35rem;
  margin-left: 1rem;

  &:hover {
    border-color: $CIPIA_BLUE;
  }
}

.nav-btn--active {
  border-color: $LIGHTER_BLUE;
  &:hover {
    background-color: $CIPIA_BLUE;
    border-color: $LIGHTER_BLUE;
  }
}

.dropdown-toggle > a::after {
  display: none;
}
