.action-btn {
  color: #3587eb;
  border: 1px solid #353535;
  background-color: white;
  border-radius: 0.4rem;
  height: 2rem;
  line-height: normal;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.action-btn:hover {
  color: #3587eb;
  background-color: #ebf4ff;
}
