@import "../../../css/constants.scss";

.button {
  background-color: rgb(0, 0, 0, 0); // transparent
  border: none;
}

.circle-button {
  height: 2.6rem;
  width: 2.6rem;
  background-color: white;
  border: 1px solid $LIGHT_GRAY_5;
  border-radius: 1.8rem;
  box-shadow: 0px 0px 10px -1px black;
  padding-top: 5px;
}

.circle-button:hover {
  background-color: $LIGHT_LIGHT_BLUE;
  border-color: $LIGHT_BLUE;
}
