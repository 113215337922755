@import "../../../../css/constants.scss";

.header {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.notificationIcon {
  width: 20px;
  height: 20px;
}

.notification-step {
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
  color: #4D4D4D;
}

.notification-step-active {
  color: #000000;
  font-weight: 600;
}

.seperator {
  width: 100%;
  background: #727272;
  height: 1px;
}

.rule-form-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
}

.required {
  color: #FF8000;
  margin-left: 5px;
}

.events-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #000000;
}

.events {
  border: 1px solid #B5B5B5;
  height: 216px;
  overflow-y: auto;
  padding: 5px;
  position: relative;
}

.button {
  min-width: 100px;
  justify-content: center
}

.timePicker {
  margin-top: 0px !important;
}

.timePicker>div {
  padding-right: 7px !important;
}

.timePicker>div>div {
  margin-left: 0px !important;
}

.timePicker>div>input {
  height: 14px !important;
}

.schedule-item {
  border-bottom: 1px solid #E0E0E0;
  min-height: 133px;
}

.schedule-item:has(input:checked[type=checkbox]) {
  background-color: #E1EFFF;
}

.schedule-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 66px;
  color: #353535;
}

.schedule-time-header {
  font-size: 14px;
  font-weight: 400;
  color: #707070;
}

.user {
  border-bottom: 1px solid #E0E0E0;
}

.errorMsg {
  color: #ff3e58;
}

/* Shared styles */

.box {
  user-select: none;
}

.box>input[type=checkbox] {
  width: 18px;
  height: 18px;
  border: 2px solid #49454F;
  border-radius: 2px !important;
}

.boxlabel {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 5px;
  color: #353535;
}

.box>input:checked[type=checkbox] {
  background-color: #4E6582 !important;
  border-color: #4E6582 !important;
}

.box:not(.disabled) :global(.custom-control-label) {
  cursor: pointer;
}

.box :global(.custom-control-label::before) {
  box-shadow: none !important;
  transition: none;
}

/* Checkbox styles */
.box.checkbox :global(.custom-control-label::before) {
  background-color: #fff !important;
  border: 2px solid #707070 !important;
  border-radius: 3px;
}

/* Checkbox Checked */
.box.checkbox :global(.custom-control-input:checked ~ .custom-control-label::before) {
  background-color: #fd9441 !important;
  border-color: #fd9441 !important;
}

/* Checkbox Disabled */
.box.checkbox :global(.custom-control-input:disabled ~ .custom-control-label::before) {
  opacity: 0.6;
}
