
.leaflet-bar {
  border: none !important;
  box-shadow: 1px 1px 3px 1px #666666 !important;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.leaflet-popup-content {
  margin: 0.5rem;
}