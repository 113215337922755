@import "./constants.scss";

.tooltip.show {
  opacity: unset !important;
}

.tooltip-arrow::before {
  display: none !important;
}
.tooltip-inner {
  background-color: $DARK_GRAY_1 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 0.5rem 1rem;
}
