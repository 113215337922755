@import "../../css/constants.scss";

.wrapper {
  background-color: $LIGHT_LIGHT_GRAY;
}

.container {
  background-color: white;
  width: 50rem;
  height: 37.5rem;
  box-shadow: 0px 4px 6px 0px #00000059;
  border-radius: 1rem;
}

.inner-container {
  background-color: $LIGHT_LIGHT_GRAY;
  width: 75%;
  border-radius: 1rem;
}
