.overlayText {
    overflow: hidden;
    text-overflow: ellipsis;
}

.schedule {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }