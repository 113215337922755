@use "../../css/constants.scss";

.table-wrapper {
  height: calc(100vh - 18.5rem);
  overflow: auto;
}

.table {
  height: 1px;
  vertical-align: middle;
}

.thead {
  background-color: constants.$LIGHT_GRAY_6;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.tr {
  height: 4rem;
  cursor: pointer;
}

.th {
  border: none;
  padding: 0.5rem 0.25rem !important;
}

.th:first-of-type {
  border-radius: 6px 0px 0px 0px;
  padding-left: 0.75rem !important;
}
.th:last-of-type {
  border-radius: 0px 6px 0px 0px;
}
.td {
  padding: 0.5rem 0.25rem !important;

  &:first-of-type {
    padding-left: 0.75rem !important;
  }
}

.eventtype {
  background-color: #A8CFFF;
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.eventtype-none {
  background-color: #E0E0E0;
  padding: 8px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.custom-link {
  cursor: pointer;
  color: #3587EB;
  text-decoration: underline;
  /* Removes underline */
}

