.toggle-button {
  width: 9rem;
  height: 2.5rem;
  border: 2px solid #3587EB !important;
  color: #353535;
  background-color: #FFFFFF; 
}

.toggle-button:hover {
  color: #353535;
  background-color: #E1EFFF;
}

.toggle-button.active {
  color: white;
  font-weight: bold;
  background-color: #3587EB ;
}

.toggle-button.active:hover {
  background-color: #77B4FF;
}

.green-circle {
  margin-right: 0.25rem;
}
