@import "../../css/constants.scss";

.table-wrapper {
  height: calc(100vh - 16rem);
  overflow: auto;
}

.table {
  height: 1px;
  vertical-align: middle;
}

.thead {
  background-color: $LIGHT_GRAY_6;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.th {
  border: none;
  padding: 0.5rem 0.5rem !important;
}

.th:first-of-type {
  border-radius: 6px 0px 0px 0px;
  padding-right: 0rem !important;
  padding-left: 0.25rem !important;
}

.th:last-of-type {
  border-radius: 0px 6px 0px 0px;
}

.tr:hover {
  background-color: $LIGHT_LIGHT_BLUE;
}

.td {
  padding: 0.75rem 0.5rem !important;
}
.td:first-of-type {
  padding-right: 0rem !important;
  padding-left: 0.25rem !important;
}

.selected-row {
  background-color: $LIGHT_LIGHT_GRAY;
}

.dropdown-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;

  select:focus {
    outline: none;
    border: 1px solid #3587EB;
    box-shadow: none;
    border-radius: 0.5rem;
  }
}

.dropdown-wrapper select {
  padding: 4px 8px;
  appearance: none;
  /* Remove native dropdown arrow */
  -webkit-appearance: none;
  /* For Safari */
  padding-right: 30px;
  /* Space for the custom arrow */
  border-width: 2px;
  background-image: none !important;

}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  /* Allow clicks to pass through to the select */
  font-size: 14px;
  color: #000;
}