.dropdown-menu {
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.35);
  max-height: 50vh;
  overflow: auto;
  z-index: 10000000;
}

.dropdown-header {
  padding-top: 0;
}

.dropdown-item:hover {
  background-color: $LIGHT_LIGHT_BLUE;
}
