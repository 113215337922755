@import "../../../css/constants.scss";

.expanding-tree-dropdown {
  min-width: 100%;
  max-height: 50vh;
}

.react-checkbox-tree {
  label {
    white-space: nowrap;
    width: 100%;
    &:hover {
      background-color: $LIGHT_LIGHT_BLUE !important;
    }
  }
  img {
    width: auto;
    height: auto;
  }
  label {
    display: flex;
    align-items: center;
  }
  .rct-checkbox {
    padding: 0;
  }
  .rct-title {
    padding: 0 0.25rem;
  }
}
