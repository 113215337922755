@import "../../../css/constants.scss";

 
.line {
  position: absolute;
  margin-left: -9px;
  background: #b5b5b5;
  width: 2px;
  height: 748px;
  margin-top: -52px;
  opacity: 1;
}

.capture-button {
  margin-right: 13%;
}
 