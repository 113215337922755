@import "../../css/constants.scss";

.container {
  background-color: $LIGHT_LIGHT_GRAY;
  height: 3.5rem;
  column-gap: min(3rem, 2%);
  border-top: 1px solid $LIGHT_GRAY_5;
  text-align: center;
}

.input {
  width: 3rem;
  text-align: center;
  border: 1px solid $GRAY_5;
  border-radius: 0.25rem;
  background-color: transparent;
  font-weight: 600;
}

/* Remove arrows Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows Firefox */
.input[type="number"] {
  -moz-appearance: textfield;
}
