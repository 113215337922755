/* Shared styles */


.box {
  user-select: none;
}

.box>input[type=checkbox] {
  width: 18px;
  height: 18px;
  border: 2px solid #49454F;
  border-radius: 2px;
}

.specialswitch>input[type=checkbox] {
  height: 2rem !important;
  width: 3.5rem !important;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='12' y='12' width='24' height='24' rx='12' fill='white'/%3E%3Cpath d='M22 26.78L19.22 24L18.2733 24.94L22 28.6667L30 20.6667L29.06 19.7267L22 26.78Z' fill='%2321005D'/%3E%3C/svg%3E%0A") !important;
  border-radius: 28px !important;
  border: 0px !important;
  background-size: 40px 40px !important;
  background-color: #727272 !important;
  &:checked {
    background-position: right center;
    background-color: #3587eb !important;
  }
}

.boxlabel {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 5px;
  color: #353535;
}

.box>input:checked[type=checkbox] {
  background-color: #4E6582;
  border-color: #4E6582;
}

.box:not(.disabled) :global(.custom-control-label) {
  cursor: pointer;
}

.box :global(.custom-control-label::before) {
  box-shadow: none !important;
  transition: none;
}

/* Checkbox styles */
.box.checkbox :global(.custom-control-label::before) {
  background-color: #fff !important;
  border: 2px solid #707070 !important;
  border-radius: 3px;
}

/* Checkbox Checked */
.box.checkbox
  :global(.custom-control-input:checked ~ .custom-control-label::before) {
  background-color: #fd9441 !important;
  border-color: #fd9441 !important;
}

/* Checkbox Disabled */
.box.checkbox
  :global(.custom-control-input:disabled ~ .custom-control-label::before) {
  opacity: 0.6;
}

/* Radio  styles */
.box.radio :global(.custom-control-label::before) {
  background-color: #fff !important;
  border: 2px solid #707070 !important;
}

/* Radio Checked */
.box.radio
  :global(.custom-control-input:checked ~ .custom-control-label::before) {
  border-color: #fd9441 !important;
}

.box.radio
  :global(.custom-control-input:checked ~ .custom-control-label::after) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='%23fd9441'/></svg>") !important;
  background-size: 75%;
}

/* Radio Disabled */
.box.radio
  :global(.custom-control-input:disabled ~ .custom-control-label::before) {
  opacity: 0.6;
}
