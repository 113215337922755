@import "../../css/constants.scss";

.side-bar {
  height: 100%;
  color: white;
  background-color: $DARK_GRAY_1;
  position: relative;
}

.side-bar-list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
  background-color: $DARK_GRAY_1;
  position: relative;
}
 
.side-tree--closed {
  display: none;
}

.row {
  padding: 0.2rem 0.3rem;
  margin: 0.35rem 0.25rem;
  border-radius: 0.375rem;
  background-color: $DARK_GRAY_1;
  &:hover {
    background-color: $GRAY_2;
  }
}

.checkbox-icon {
  width: 40px;
}

.name {
  padding-left: 0.25rem;
  font-size: 16px;
  vertical-align: middle;
}

.right-click-menu {
  background-color: white;
  color: black;
  padding: 0.25rem;
  width: 8rem;
  z-index: 10;
  box-shadow: 2px 0px 12px 1px black;
}
