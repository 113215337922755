@import "../../../css/constants.scss";

.header {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.seperator {
  width: 100%;
  background: #E0E0E0;
  height: 1px;
}

.headerText {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
}

.itemHeader {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  color: #727272;
}

.itemValue {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #000000;
  white-space: break-spaces;
}

.h-90 {
  height: 90% !important;
}

.mt-2-4 {
  margin-top: 2.4rem;
}

.tabs {
  border-bottom: none !important;

  li {
    box-shadow: 0px 4px 2px 0px #0000000D;
    border-width: 2px 0px 2px 2px;
    border-style: solid;
    border-color: #379CD8;
  }

}

.mediaItem{
  min-height: 300px;
}

.eventDetailValueStyle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
}

.modal-loader-height{
  min-height: calc(100vh - 250px);
}