@import "../../../css/constants.scss";

.photo-container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  background-color: $LIGHT_LIGHT_GRAY;
  border: 1px solid #B5B5B5;
  border-radius: 6px;
}

.photo {
  max-height: 578px;
}

.capture-big-icon {
  top: 5rem;
  left: 9rem;
  margin: auto;
}