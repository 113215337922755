@import "./constants.scss";

.app-form-input:not(textarea) {
  height: 2rem;
}

.app-form-input::placeholder {
  color: #b2b0b0 !important;
}

.app-form-input.placeholder {
  background: #f9f9f9;
}

.app-form-input.invalid {
  border: 2px solid #ff3e58 !important;
}

.app-form-input.disabled {
  background: #e9e9e9 !important;
  box-shadow: none !important;
}

.app-form-input.with-text.disabled {
  border: 1px solid #b2b0b0 !important;
  background: #feeede !important;
  box-shadow: none !important;
}

.app-form-input.textarea {
  min-height: 4.3125rem;
  fill: violet;
}

.app-form-input-dropdown {
  color: #878787 !important;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.app-form-input-dropdown.value-selected {
  color: #000 !important;
}

.app-form-input-dropdown:focus {
  box-shadow: none !important;
}
.with-image.form-switch .form-check-input{
  background-color: #E0E0E0;
  background-image: url("../assets/checkbox/uncheckX.svg") !important;
  height: 1.5rem;
  width: 2.5rem;
  &:checked {
    background-position: right center;
    background-color: $CIPIA_BLUE;
    background-image: url("../assets/checkbox/checked.svg")  !important;
  }
}

.form-switch .form-check-input {
  height: 2rem ;
  width: 3.5rem ;
  background-color: $CIPIA_BLUE !important;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='12' y='12' width='24' height='24' rx='12' fill='white'/%3E%3Cpath d='M22 26.78L19.22 24L18.2733 24.94L22 28.6667L30 20.6667L29.06 19.7267L22 26.78Z' fill='%2321005D'/%3E%3C/svg%3E%0A") !important;
  border-radius: 28px !important;
  border: 0px !important;
  background-size: 40px 40px !important;
  background-color: #727272;
  &:checked {
    background-position: right center;
  }
}

.app-form-label {
  color: $LABEL_COLOR;
}
