@import "../../css/constants.scss";

.container {
  height: 100%;
  // margin-bottom: 1rem;
}

.form-container {
  //width: 34rem;
  //height: 36rem;
  margin: 1rem;
  padding: 2rem 5rem;
  overflow-y: auto; 
  //border-radius: 1rem;
  //background-color: $LIGHT_LIGHT_GRAY;
}

.format-selected-display {
  background-color: $LIGHT_LIGHT_BLUE;
  height: 10rem;
  width: 80%;
  border-radius: 0.5rem;
  margin: 1rem 0;
}

.user-settings-header {
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  text-align: left;
}

.seperator {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 680px;
  height: 1px;
  background-color:  #000000;
}

.active {
  color: #000000;
}

.inactive {
  color: #B5B5B5;
}

.save-btn {
  min-width: 100px;
}

.big-seperator {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 1000px;
  height: 1px;
  background-color:  #000000;
}
