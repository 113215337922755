.button-container {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0.7rem;
  position: absolute;
  right: 1%;
  width: 22rem;
  height: 2.5rem;
  top: 0.5rem;
  z-index: 400;
}

.left-button {
  border-radius: 1rem 0 0 1rem;
  margin: 0.25rem 0 0 1rem;
  border-right: none !important;
}

.right-button {
  border-radius: 0 1rem 1rem 0;
  margin: 0.25rem 0.75rem 0 0;
}

:global(.leaflet-popup-content) {
  width: auto !important;
}
