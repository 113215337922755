.modal-form-dialog {
  max-width: 40rem;
}

.medium-modal-form-dialog {
  max-width: 40rem;
  min-width: 820px;
}

.modal-form-header {
  height: 2.75rem;
  padding: 0rem 1rem;
  background-color: #e0e0e0;
  font-weight: bold;
  font-size: large;
}

.modal-form-body {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.modal-form-content {
  min-width: 700px;
}

.modal-form-footer {
  height: 4.5rem;
  padding: 0rem 2.5rem;
  background-color: white;
  border-top-color: #b5b5b5;
}

.modal-customr-form-footer {
  height: 4.5rem;
  padding: 0rem 2.5rem;
  background: #FAFAFA;
  border-top: 1px solid #E0E0E0;
}

.modal-custom-footer {
  height: 4.5rem;
  padding: 0rem 2.5rem;
  background: #FAFAFA;
  border-top: 1px solid #E0E0E0;
  justify-content: space-between !important;
}

.big-modal-form-dialog {
  max-width: 80vw;
}
.big-modal-form-body {
  padding: 0.5rem;
}

.large-modal-form-dialog {
  max-width: 40rem;
  min-width: 1020px;
}