.customUnderlinedText {
    display: flex;
    flex-direction: column;
  }

  .text {
    font-size: 16;
    font-weight: 500;
    line-height: 20px;
  }

  .green {
    color: #2ed47f;
  }
  
  .red {
    color: #df0031;
  }
  
  .normal {
    color: #000000;
  }
  
  .underline {
    border-top: 1px solid #727272;
    width: 100%;
    margin-top: 5px;
  }
  